@import '../../../../scss/theme-bootstrap';

.mpp-sort {
  text-align: center;
  @include breakpoint($large-up) {
    float: $rdirection;
    padding-bottom: 20px;
    position: relative;
    z-index: 1;
  }
  &-menu {
    min-width: 50%;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include breakpoint($medium-up) {
      min-width: 200px;
      width: auto;
    }
    &.selectBox {
      @include typography-body-text();
      height: 40px;
      background-position: 98% center;
      background-color: $color-white;
      background-image: none;
      margin-bottom: 0;
      text-align: $ldirection;
      padding-#{$rdirection}: 30px;
      padding-#{$ldirection}: 15px;
      .selectBox-arrow {
        @include select-arrow--down;
      }
      &:hover {
        color: $color-black;
      }
    }
    &--arrow-down {
      @include select-arrow--down;
      width: 30px;
      height: 38px;
      position: absolute;
      top: 1px;
      #{$rdirection}: 1px;
      background: $color-white;
      pointer-events: none;
      @include breakpoint($medium-up) {
        display: none;
      }
    }
    .selectBox-label {
      display: flex;
      justify-content: $rdirection;
      align-items: center;
      width: 100%;
      height: 100%;
      float: $rdirection;
    }
  }
}
